@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400&display=swap");
@keyframes fade-in {
  from {
    margin-left: 100%;
    width: 300%; }
  to {
    margin-left: 0%;
    width: 100%; } }

* {
  font-family: 'Raleway', sans-serif; }

body {
  background-color: #181818 !important;
  color: rgba(250, 250, 250, 0.8) !important; }

header {
  margin-bottom: 3vh; }
  header > div {
    padding: 1vw 3vw !important;
    background-color: #3798cc !important;
    color: #181818; }
    @media (min-width: 600px) {
      header > div {
        padding: 1vw 5vw !important; } }
    header > div #introduction .identity {
      margin-bottom: 0.5em; }

@media (min-width: 360px) and (orientation: portrait) {
  header > div #introduction .identity {
    margin-bottom: 5vh; } }

@media (min-width: 360px) and (orientation: landscape) {
  header > div #introduction .identity {
    margin-bottom: 2vw; } }

@media (min-width: 900px) and (orientation: landscape) {
  header > div #introduction .identity {
    margin-bottom: 2vw; } }
      header > div #introduction .identity h1 {
        margin-top: 0;
        margin-bottom: 0;
        font-weight: lighter;
        text-transform: uppercase;
        text-align: center;
        font-size: 2em; }
        @media (min-width: 600px) {
          header > div #introduction .identity h1 {
            font-size: 6vw;
            text-align: left; } }
        @media (min-width: 900px) {
          header > div #introduction .identity h1 {
            font-size: 4vw; } }
        @media (min-width: 1200px) {
          header > div #introduction .identity h1 {
            font-size: 3vw; } }
      header > div #introduction .identity h2 {
        margin-top: 0;
        margin-bottom: 0;
        font-weight: light;
        font-size: 0.8em;
        text-transform: lowercase;
        text-align: center; }
    header > div #introduction .contacts {
      text-align: center; }

@media (min-width: 360px) and (orientation: portrait) {
  header > div #introduction .contacts {
    text-align: left;
    margin-left: 2vw; } }

@media (min-width: 360px) and (orientation: landscape) {
  header > div #introduction .contacts {
    text-align: left; } }
      @media (min-width: 900px) {
        header > div #introduction .contacts {
          text-align: left; } }
  @media (min-width: 900px) and (orientation: landscape) {
    header > div #introduction .contacts {
      margin-left: 2vh; } }

@media (min-width: 900px) and (orientation: landscape) {
  header > div #introduction .contacts > div {
    width: 7em; } }
    header > div .mail-modal {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
      background-color: #00000080; }
      @media (min-width: 900px) {
        header > div .mail-modal {
          width: 100vw; } }
      header > div .mail-modal-content {
        position: relative;
        height: 100vh; }
        @media (min-width: 900px) {
          header > div .mail-modal-content {
            margin-top: 10vh;
            margin-left: 20vw;
            max-width: 60vw; } }
        header > div .mail-modal-content .modal-close {
          position: absolute;
          cursor: 'pointer';
          top: 1.3vh;
          right: 2vw; }
          @media (min-width: 600px) {
            header > div .mail-modal-content .modal-close {
              right: 1vw; } }
        header > div .mail-modal-content .modal-header {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #3798cc;
          color: white;
          height: 5vh; }
        header > div .mail-modal-content .modal-body {
          background-color: #181818;
          overflow-y: hidden;
          display: flex;
          flex-direction: column;
          padding: 1em 1em 1em 1em;
          height: 100vh; }
          @media (min-width: 900px) {
            header > div .mail-modal-content .modal-body {
              flex-direction: row;
              justify-content: space-around;
              max-height: 65vh; } }
          @media (min-width: 1200px) {
            header > div .mail-modal-content .modal-body {
              max-height: 50vh; } }
          header > div .mail-modal-content .modal-body > .contact-me {
            color: white;
            font-size: 0.7em; }
            @media (min-width: 900px) {
              header > div .mail-modal-content .modal-body > .contact-me {
                margin-top: 1em;
                width: 45%;
                font-size: 0.8em; } }
          header > div .mail-modal-content .modal-body > form {
            display: flex;
            flex-direction: column;
            padding: 1em 0 5em 0;
            align-items: flex-start;
            color: white; }
            @media (min-width: 900px) {
              header > div .mail-modal-content .modal-body > form {
                width: 50%; } }
            header > div .mail-modal-content .modal-body > form textarea {
              width: 100%;
              height: 20vh; }
            header > div .mail-modal-content .modal-body > form label {
              line-height: 2em; }
            header > div .mail-modal-content .modal-body > form input {
              width: 100%; }
            header > div .mail-modal-content .modal-body > form input[type="submit"] {
              margin-top: 1em;
              height: 2em;
              min-width: 6em;
              width: 15vw;
              margin-left: auto;
              background-color: #3798cc;
              color: white;
              border: none;
              border-radius: 0.5em;
              font-size: small;
              font-weight: bold;
              cursor: pointer; }
              @media (min-width: 600px) {
                header > div .mail-modal-content .modal-body > form input[type="submit"] {
                  min-width: none;
                  width: 10px; } }
              header > div .mail-modal-content .modal-body > form input[type="submit"]:hover {
                background-color: rgba(55, 152, 204, 0.8); }
              header > div .mail-modal-content .modal-body > form input[type="submit"]:active {
                background-color: rgba(55, 152, 204, 0.6); }
            header > div .mail-modal-content .modal-body > form .button-pending {
              margin-top: 1em;
              height: 2em;
              min-width: 6em;
              width: 6vw;
              margin-left: auto;
              padding-left: 0.5em;
              background-color: orange;
              color: white;
              border: none;
              border-radius: 0.5em;
              font-size: small;
              font-weight: bold; }
            header > div .mail-modal-content .modal-body > form .button-success {
              margin-top: 1em;
              height: 2em;
              min-width: 6em;
              width: 5vw;
              margin-left: auto;
              padding-left: 1em;
              background-color: green;
              color: white;
              border: none;
              border-radius: 0.5em;
              font-size: small;
              font-weight: bold; }
    header > div img {
      display: none;
      position: absolute;
      border-radius: 10em; }
      @media (min-width: 360px) {
        header > div img {
          display: unset; } }
  @media (min-width: 360px) and (orientation: portrait) {
    header > div img {
      width: 20vh;
      top: 30vw;
      right: 3vh; } }
  @media (min-width: 360px) and (orientation: landscape) {
    header > div img {
      width: 48vw;
      top: 15vw;
      right: 6vw; } }

@media (min-width: 600px) and (orientation: portrait) {
  header > div img {
    top: 1.5vh;
    right: 1vh;
    width: 25vw; } }

@media (min-width: 600px) and (orientation: landscape) {
  header > div img {
    top: 1vh;
    right: 2vw;
    width: 25vw; } }
      @media (min-width: 900px) {
        header > div img {
          display: unset; } }
  @media (min-width: 900px) and (orientation: portrait) {
    header > div img {
      width: 12vh;
      top: 1vh; } }
  @media (min-width: 900px) and (orientation: landscape) {
    header > div img {
      width: 17vw;
      top: 5vh;
      right: 5vw; } }

@media (min-width: 1200px) and (orientation: portrait) {
  header > div img {
    width: 12vh;
    top: 1vh; } }

@media (min-width: 1200px) and (orientation: landscape) {
  header > div img {
    display: unset;
    width: 20vh;
    top: 1vh; } }

main {
  position: relative;
  display: flex !important;
  flex-direction: column-reverse;
  padding-left: 1em;
  padding-right: 1em;
  background-color: #181818; }
  @media (min-width: 360px) {
    main {
      padding-right: 1em; } }
  @media (min-width: 900px) {
    main {
      flex-direction: row; } }
  @media (min-width: 900px) and (orientation: landscape) {
    main {
      margin: 2vh 0vw 2vh 0vw; } }
  main aside {
    display: flex;
    flex-direction: column-reverse;
    margin: 0em !important; }
    @media (orientation: portrait) {
      main aside {
        margin: 1vh 0 0 -7vh; } }
    @media (orientation: landscape) {
      main aside {
        margin-left: -7vw; } }

@media (min-width: 360px) and (orientation: portrait) {
  main aside {
    margin-left: -5vh; } }

@media (min-width: 360px) and (orientation: landscape) {
  main aside {
    margin-left: -7vw; } }
    @media (min-width: 900px) {
      main aside {
        flex-direction: column;
        max-width: 40em !important; } }
  @media (min-width: 900px) and (orientation: portrait) {
    main aside {
      margin-left: -15vw;
      margin-right: 10em; } }
  @media (min-width: 900px) and (orientation: landscape) {
    main aside {
      width: 40vw;
      margin-left: -4vh;
      margin-right: 0; } }
    main aside h3 {
      margin-left: unset;
      display: inline-block;
      margin-top: 0em;
      margin-bottom: 0.2em !important;
      font-size: 1.7em;
      font-weight: normal;
      text-transform: uppercase; }
    main aside #about {
      display: flex;
      flex-direction: column;
      font-size: smaller; }
    main aside #skills-hard > div, main aside #skills-soft > div {
      display: flex;
      flex-wrap: wrap;
      grid-template-columns: 1fr 1fr; }
      main aside #skills-hard > div div, main aside #skills-soft > div div {
        flex: 0 0 20%; }
        @media (min-width: 1200px) {
          main aside #skills-hard > div div svg, main aside #skills-soft > div div svg {
            margin: 0 -2em 0 0; } }
        @media (min-width: 360px) {
          main aside #skills-hard > div div, main aside #skills-soft > div div {
            flex: 0 0 15%; } }
        @media (min-width: 600px) {
          main aside #skills-hard > div div, main aside #skills-soft > div div {
            flex: 0 0 10%; } }
        @media (min-width: 900px) {
          main aside #skills-hard > div div, main aside #skills-soft > div div {
            flex: 0 0 20%; } }
        @media (min-width: 1200px) {
          main aside #skills-hard > div div, main aside #skills-soft > div div {
            flex: 0 0 8vh; } }
  main #timeline {
    padding: 1em; }

@media (min-width: 600px) and (orientation: portrait) {
  main #timeline {
    margin-left: 0em; } }

@media (min-width: 600px) and (orientation: landscape) {
  main #timeline {
    margin-left: 1vw; } }
    main #timeline .item-enter {
      opacity: 0;
      transition: opacity 500ms ease-out; }
    main #timeline .item-enter-active {
      opacity: 1;
      transition: opacity 500ms ease-out; }
    main #timeline .item-exit {
      opacity: 1;
      transition: opacity 500ms ease-in; }
    main #timeline .item-exit-active {
      opacity: 0;
      transition: opacity 500ms ease-in; }
    main #timeline .mantine-Timeline-item::before {
      left: -1.5em; }
    main #timeline .mantine-Timeline-itemBullet {
      left: -1.9em; }
    main #timeline .mantine-Timeline-itemBody .mantine-Timeline-itemTitle {
      color: rgba(250, 250, 250, 0.8); }
    main #timeline .mantine-Timeline-itemBody .badges {
      margin-top: 1vh; }
